@import url('https://fonts.googleapis.com/css2?family=Cantarell&family=Poppins&family=Spectral&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
 
  background-color: "whitesmoke";
  height: 100vh;
  overflow-y: auto;
  
}


